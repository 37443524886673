import React from "react"

import '@fortawesome/fontawesome-svg-core/styles.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faInstagramSquare,
  faFacebookSquare,
  faTwitterSquare,
  faYoutubeSquare,
  faLinkedin
} from "@fortawesome/free-brands-svg-icons"

import styles from './Contact.module.css'

const ContactSocialIcons = () => (
  <>
    <a
              href="https://www.facebook.com/SymphonyForSeniors/"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Symphony For Seniors on Facebook"
              title="Symphony For Seniors on Facebook"
            >
              <FontAwesomeIcon
                icon={faFacebookSquare}
                className={styles.contactSocialIcon}
                aria-hidden="true"
              />
            </a>
            <a
              href="https://twitter.com/SymphonySeniors"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Symphony For Seniors on Instagram"
              title="Symphony For Seniors on Instagram"
            >
              <FontAwesomeIcon
                icon={faTwitterSquare}
                className={styles.contactSocialIcon}
                aria-hidden="true"
              />
            </a>
            <a
              href="https://www.linkedin.com/in/symphony-for-seniors-6a89311b6/"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Symphony For Seniors on LinkedIn"
              title="Symphony For Seniors on LinkedIn"
            >
              <FontAwesomeIcon
                icon={faLinkedin}
                className={styles.contactSocialIcon}
                aria-hidden="true"
              />
            </a>
            <a
              href="https://www.instagram.com/symphonyforseniors/"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Symphony For Seniors on Instagram"
              title="Symphony For Seniors on Instagram"
            >
              <FontAwesomeIcon
                icon={faInstagramSquare}
                className={styles.contactSocialIcon}
                aria-hidden="true"
              />
            </a>

            <a
              href="https://www.youtube.com/channel/UC0OnqBEcEJG491Sw8B0ER7A?reload=9&view_as=subscriber"
              target="_blank"
              rel="noopener noreferrer"
              role="icon"
              aria-label="Symphony For Seniors on YouTube"
              title="Symphony For Seniors on YouTube"
            >
              <FontAwesomeIcon
                icon={faYoutubeSquare}
                className={styles.contactSocialIcon}
                aria-hidden="true"
              />
            </a>
  </>
)

export default ContactSocialIcons
