import React from "react";
import styled from "styled-components"

const Icon = styled.svg `
  width: 41px;
  height: 41px;
  margin-right: 10px;
  
  .cls1{
    fill: #3c5272;
  }
  .cls1:hover {
    fill: #7CB3FE;
  }
`;

const FacebookIcon = () => {
    return (
<a href="https://www.facebook.com/SymphonyForSeniors/" target="_blank" rel="noopener noreferrer">
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.55 41.55">
  <title>facebook</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls1" d="M20.775,0A20.775,20.775,0,1,1,0,20.775,20.775,20.775,0,0,1,20.775,0ZM17.06967,33.44149h4.4V21.36874h3.29366l.65274-3.77274H21.4637V14.86377c0-.88181.57041-1.807,1.38484-1.807h2.24567V9.28551H22.35449V9.302c-4.3087.14971-5.1935,2.64839-5.26985,5.26685h-.015V17.596H14.86891v3.77274h2.20076Z"/>
    </g>
  </g>
</Icon>
</a>
    )
}

export default FacebookIcon
