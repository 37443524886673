import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"

import ContactHero from "../components/ContactPage/ContactHero"
import HoverCardBlock from "../components/HoverCardBlock/HoverCardBlock"
import FooterContact from "../components/FooterContact/FooterContact"
import Assessment from "../components/Assessment/Assesment"

import ContactSocialIcons from "../components/ContactPage/ContactSocialIcons"

import FacebookIcon from "../images/icons/Facebook"
import InstagramIcon from "../images/icons/Instagram"
import LinkedinIcon from "../images/icons/Linkedin"
import TwitterIcon from "../images/icons/Twitter"
import YoutubeIcon from "../images/icons/Youtube"
import BlogBlock from "../components/OurTeamPage/BlogBlock"

import styles from "../components/ContactPage/Contact.module.css"

const Contact = ({ element }) => {
  // Query for HoverCardBlock image
  const data = useStaticQuery(graphql`
    query {
      ourTeamHoverCard: file(relativePath: { eq: "our-team-hovercard.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800, quality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Connect With Us" />
      <ContactHero>
        <Container className="hero-container">
          <Row className="hero-flex-row">
            <Col style={{ marginBottom: "50px" }}>
              <h1 className={styles.heroHeadline}>
                Staying in touch is so important.
              </h1>
              <h2 className="hero-subhead">
                Symphony takes the stress out of the daily routine, so seniors
                have more time to connect with what is most important in their
                lives.
              </h2>
            </Col>
          </Row>
        </Container>
      </ContactHero>
      <Container>
        <Row>
          <Col>
            <HoverCardBlock
              fluid={data.ourTeamHoverCard.childImageSharp.fluid}
              alt="Let our experts guide you"
              // style={{alignSelf: 'flex-end', marginBottom: '-40px'}}
            >
              <div>
                <p className={styles.hoverCardTitle}>Connect With Us</p>
                <Row>
                  <Col sm={6}>
                    <p className={styles.hoverCardText}>Location</p>
                  </Col>
                  <Col sm={6}>
                    <p className={styles.homeCardBody}>
                      5635 N. Scottsdale Rd.
                      <br />
                      Suite 170
                      <br />
                      Scottsdale, AZ 85250
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6}>
                    <p className={styles.hoverCardText}>Contact</p>
                  </Col>
                  <Col sm={6}>
                    <p className={styles.homeCardBody}>
                      <a href="tel:(480) 645-0372">(480) 645-0372</a>
                      <br />
                      <a href="mailto:info@symphonyforseniors.com">
                        info@symphonyforseniors.com
                      </a>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col sm={4}>
                    <p className={styles.hoverCardText}>Follow Us</p>
                  </Col>
                  <Col sm={8} className="text-right">
                    <ContactSocialIcons />
                    
                    {/* <FacebookIcon />
                    <InstagramIcon />
                    <YoutubeIcon />
                    <TwitterIcon />
                    <LinkedinIcon /> */}
                  </Col>
                </Row>
              </div>
            </HoverCardBlock>
          </Col>
        </Row>
      </Container>

      <FooterContact />

      <Assessment />

      <Container>
        <Row>
          <Col>
            <BlogBlock />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Contact
