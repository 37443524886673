import React from "react";
import styled from "styled-components"

const Icon = styled.svg `
 width: 41px;
  height: 41px;
  margin-right: 10px;
  
  .cls1{
    fill: #3c5272;
  }
  .cls1:hover {
    fill: #7CB3FE;
  } 
`;

const InstagramIcon = () => {
    return (
<a href="https://www.instagram.com/symphonyforseniors/" target="_blank" rel="noopener noreferrer">
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.55 41.55">
  <title>instagram</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls1" d="M24.881,14.799a1.0815,1.0815,0,1,0,0-.001Z"/>
      <path class="cls1" d="M20.36,15.846a4.513,4.513,0,1,0,4.513,4.513A4.513,4.513,0,0,0,20.36,15.846Zm0,7.27a2.757,2.757,0,1,1,2.757-2.758A2.757,2.757,0,0,1,20.36,23.116Z"/>
      <path class="cls1" d="M20.775,0A20.775,20.775,0,1,1,0,20.775,20.775,20.775,0,0,1,20.775,0Zm8.312,23.886V16.834h0a5.2,5.2,0,0,0-5.2-5.2H16.835a5.2,5.2,0,0,0-5.2,5.2v7.049h0a5.2,5.2,0,0,0,5.2,5.2h7.051A5.2,5.2,0,0,0,29.087,23.886Z"/>
    </g>
  </g>
</Icon>
</a>
    )
};

export default InstagramIcon
