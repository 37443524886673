import React from "react";
import styled from "styled-components"

const Icon = styled.svg `
  width: 41px;
  height: 41px;
  margin-right: 10px;
  
  .cls1{
    fill: #3c5272;
  }
  .cls1:hover {
    fill: #7CB3FE;
  }
`;

const YoutubeIcon = () => {
    return (
<a href="https://www.youtube.com/channel/UC0OnqBEcEJG491Sw8B0ER7A?reload=9&view_as=subscriber" target="_blank" rel="noopener noreferrer">
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.55 41.55">
  <title>youtube</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path className="cls1" d="M20.775,0A20.775,20.775,0,1,1,0,20.775,20.775,20.775,0,0,1,20.775,0ZM31.25333,15.57912a2.70055,2.70055,0,0,0-1.90015-1.91247c-1.67608-.452-8.39694-.452-8.39694-.452s-6.72083,0-8.39694.452a2.70062,2.70062,0,0,0-1.90016,1.91247,28.33,28.33,0,0,0-.4491,5.2067,28.3299,28.3299,0,0,0,.4491,5.20669,2.66031,2.66031,0,0,0,1.90016,1.88193c1.67611.452,8.39694.452,8.39694.452s6.72082,0,8.39693-.452a2.66031,2.66031,0,0,0,1.90016-1.88193,28.3299,28.3299,0,0,0,.4491-5.20669A28.33,28.33,0,0,0,31.25333,15.57912Zm-12.4952,8.40233V17.59018l5.61731,3.19572-5.6173,3.19555Z"/>
    </g>
  </g>
</Icon>
</a>
    )
    }
export default YoutubeIcon;
