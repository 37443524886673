import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"

import BackgroundImage from "gatsby-background-image"

const CompleteCareHero = ({ className, children }) => (
  <StaticQuery
    query={graphql`
      {
        contactHero: file(relativePath: { eq: "contact-hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
  
  render={data => {
    const imageData = data.contactHero.childImageSharp.fluid

    return (
        <BackgroundImage
            tag="section"
            className={className}
            fluid={imageData}
            backgroundColor={`#7CB3FE`}
        >
            <div style={{
               background: '#7CB3FE75',
               overflow: 'hidden',
               height: '100%',
               zIndex: 2 
            }}>
            { children }
            </div>
        </BackgroundImage>
    )
  }}
  />
)

const StyledBackgroundSection = styled(CompleteCareHero)`
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 740px;
  overflow: hidden;
`
export default StyledBackgroundSection
