import React from "react";
import styled from "styled-components"

const Icon = styled.svg `
  width: 41px;
  height: 41px;
  margin-right: 10px;
  
  .cls1{
    fill: #3c5272;
  }
  .cls1:hover {
    fill: #7CB3FE;
  }
`;

const TwitterIcon = () => {
    return (
<a href="https://twitter.com/SymphonySeniors" target="_blank" rel="noopener noreferrer">
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.55 41.55">
  <title>twitter</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <path class="cls1" d="M20.775,0A20.775,20.775,0,1,1,0,20.775H0A20.775,20.775,0,0,1,20.775,0Zm8.633,13.626a6.1,6.1,0,0,1-2.354.835h0a3.773,3.773,0,0,0-6.406,3.45h0a10.452,10.452,0,0,1-7.78-3.916,3.776,3.776,0,0,0,1.145,4.987,2.426,2.426,0,0,1-1.648-.427,3.637,3.637,0,0,0,2.911,3.689,2.98906,2.98906,0,0,1-1.571.12,3.568,3.568,0,0,0,3.374,2.561,6.712,6.712,0,0,1-5.445,1.571,11.34009,11.34009,0,0,0,5.9,1.669A10.485,10.485,0,0,0,28.1146,17.77648q.00293-.31938-.0136-.63848v-.124a6.51105,6.51105,0,0,0,1.829-1.932,6.614,6.614,0,0,1-2.093.594,4.01005,4.01005,0,0,0,1.572-2.05"/>
    </g>
  </g>
</Icon>
</a>
    )
}
export default TwitterIcon
