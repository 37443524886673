import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import styles from './OurTeam.module.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLongArrowRight } from "@fortawesome/pro-light-svg-icons"
// import SideStories from "./SideStories"
 
const getBlogPosts = graphql`
query {
    allContentfulBlogPost (
      sort: { fields: [updatedAt], order: DESC }
      limit: 4
      ) {
      totalCount
      edges {
        node {
          slug
          title
          updatedAt(formatString: "dddd MMMM Do, YYYY")
          excerpt
          thumbnail: postThumbnail {
              fixed(width: 400) {
                  src
              }
          }
          image: featuredImage {
            fluid(maxWidth: 600) {
              src
              srcSet
            }
          }
        }
      }
    }
  }
`

const HomeBlogBlock = () => {
    return (
      <Container className="big-y-margin">
      <Row>
      <StaticQuery
        query={ getBlogPosts }
        render={ data => {
          const posts = data.allContentfulBlogPost.edges
          return (

            posts.map(({ node }) => {
              return (
                    <Col sm={3}>
                        <div style={{
                            width: '100%'
                        }}>
                            <img className={`${styles.featuredStoryImage} ${styles.round}`}src={node.thumbnail.fixed.src} alt={node.image.title} />
                        </div>
                        <div>
                          <Link to={`/blog/${node.slug}`}><h2 className={styles.postTitle}>{node.title}</h2></Link>
                        </div>
                        {/* <div>
                          <p>{node.updatedAt}</p>
                          <p>{node.excerpt}</p>
                        </div> */}
                        <div>
                          <p><Link to={`/blog/${node.slug}`} className={styles.readMore}>Read More <FontAwesomeIcon icon={faLongArrowRight} aria-hidden="true" style={{paddingTop: '4px'}} /></Link></p>
                        </div>
                    </Col>
              )
              
            })
   )
        }}
        
        >
      </StaticQuery>
      </Row>
      </Container>
    )
}

export default HomeBlogBlock;