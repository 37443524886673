import React from "react";
import styled from "styled-components"

const Icon = styled.svg `
  width: 41px;
  height: 41px;
  margin-right: 10px;
  
  .cls1{
    fill: #3c5272;
  }
  .cls1:hover {
    fill: #7CB3FE;
  }
`;

const InstagramIcon = () => {
    return (
<a href="https://www.linkedin.com/in/symphony-for-seniors-6a89311b6/" target="_blank" rel="noopener noreferrer">
<Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 41.54999 41.54999">
  <title>linkedin</title>
  <g id="Layer_2" data-name="Layer 2">
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path class="cls1" d="M21.67,17.127v-.043c-.007.014-.017.029-.024.043Z"/>
        <path class="cls1" d="M20.775,0A20.775,20.775,0,1,0,41.55,20.775,20.775,20.775,0,0,0,20.775,0ZM16.068,28.453H12.461V15.205h3.607ZM14.265,13.397h-.024q-.0641-.00248-.12793-.00885A2.084,2.084,0,0,1,12.247,11.107q-.00788-.08129-.00928-.163A2.088,2.088,0,0,1,14.288,8.819q.07454.002.14881.00934A2.08409,2.08409,0,0,1,16.306,11.107q.0077.08029.00922.16095A2.09,2.09,0,0,1,14.265,13.397ZM29.074,28.456H25.467V21.369c0-1.78-.522-3-1.827-3a2.06008,2.06008,0,0,0-1.85,1.611,3.63633,3.63633,0,0,0-.12,1.074v7.4H18.062s.049-12,0-13.248H21.67v1.878a3.54613,3.54613,0,0,1,3.251-2.189c2.373-.001,4.153,1.892,4.153,5.961Z"/>
      </g>
    </g>
  </g>
</Icon>
</a>
    )
}
export default InstagramIcon